import React, { useState } from 'react'
import { useInView } from 'react-hook-inview'
import './CatalogueItem.scss'

function CatalogueItem(props) {

  const [showInfo, setShowInfo] = useState(false)
  const [clickItem, setClickItem] = useState(false)
  const [ref, isVisible] = useInView()

  const setPrintPrices = () => {
    if (props.printSize === 'A4+A3' && props.artist === 'Martin Elliott') {
      return <div><p>A4: £45</p><p>A3: £65</p></div>
    } else if (props.printSize === 'A4+A3' && props.artist !== 'Martin Elliott') {
      return <div><p>A4: £29.95</p><p>A3: £39.95</p></div>
    } else {
      return <p>A4: £29.95</p>
    }
  }
  
  return (
    <div className='item-container'>
        <div onClick={() => setClickItem(true)} onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)} loading='lazy' className={showInfo ? 'item-image item-hover' : 'item-image' } style={isVisible ? {backgroundImage: `url(${props.link})`} : {backgroundImage: 'none'}} ref={ref}></div>

        {showInfo && props.sold === true && props.openType === 'paintings' ? 
        <div className={props.portrait === true ? 'item-sale-info' : 'item-sale-info-landscape'} onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)}>
            {props.sold === true ? <h3 className='sold'>ORIGINAL SOLD</h3> : ''}
            {props.print === 'true' ? <h5 className='prints'>(Prints Available)</h5> : ''}
        </div> 
        : ''}
        
        

        {clickItem ? 
        <div className='item-overlay'>
          <div className='exit-btn' onClick={() => setClickItem(false)}>
            <span className='overlay-line'></span>
            <span className='overlay-line'></span>
          </div>
          <div className='flex-container'>
          <div className='overlay-img' style={{backgroundImage: `url(${props.link})`}}></div>
          <div className='overlay-info-container'>
            <div className='overlay-info-text'>
            <h1 className='overlay-title'>{props.title}</h1>
            <h2 className='overlay-artist'>{props.artist}</h2>

            <div className='overlay-print-info'>
            <p>{props.price}</p>
            {props.sold ? <h3 style={{textDecoration: 'underline'}}>ORIGINAL SOLD</h3> : ''}
            {props.type === 'painting' && props.print === 'true' ? <div><p>{props.printSize === 'A4+A3' ? 'Giclee prints available in A4 and A3.' : 'Giclee prints available in A4.'}</p>
            {setPrintPrices()}
            </div> : ''}
            {props.type === 'photograph' ? 
            <div>
              <p>Giclee prints available in A4 and A3.</p>
              <p>A4: £29.95</p>
              <p>A3: £39.95</p>
            </div> : ''}
            </div>


            <div className='overlay-contact-info'>
            <p>Please get in touch via phone or email to make a purchase.</p>
            <p>Phone: 01736 788665</p>
            <p>Email: londonhousestudio@outlook.com</p>
            </div>
            </div>
          </div>
          </div>
        </div>
        : ''}

    </div>
  )
}

export default CatalogueItem