import React from 'react'
import './Contact.scss'

function Contact() {
  return (
    <footer id='contact'>
        <div className="contact-container">
            <div>
                <h3>London House Studio</h3>
                <p>40b Fore Street<br></br> St. Just<br></br>Penzance<br></br>Cornwall<br></br>TR19 7LJ</p>
            </div>
            <div>
                <h3>Telephone:</h3>
                <p>01736 788665</p>
            </div>
            <div>
                <h3>Email:</h3>
                <p>londonhousestudio@outlook.com</p>
            </div>
            <div>
                <h3>Opening Times:</h3>
                <p>Tues - Sat, 10:30am - 4:30pm</p>
            </div>
        </div>
    </footer>
  )
}

export default Contact