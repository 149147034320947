import React from 'react'
import './Artists.scss'

function Artists() {
  
  
  return (
    <div className='artists-container'>
      <h2>Artists</h2>
      
      <div id='artist-container-raoul' className='artist-container'>
        <div> <h3>Raoul Humphreys</h3>
        <p>I grew up in St Ives and after a short time away came back in my early 20’s and have lived here (St Just) ever since. Place is important to me, and in particular the unique natural history of Cornwall, or wherever I happen to be at a given moment in time. Growing up in St Ives one of my earliest memories is of painting birds - the ubiquitous herring gull that also featured my daily soundscape. But I didn’t continue to paint after leaving school, as I moved on to work, family and other passions. I did however retain an interest in nature and in particular nature books. Among my favourites were books that contained the Art of Tunnicliffe, Ennion and later on Lars Jonsson. I also enjoyed time with family and on my own watching nature and developing an interest in photography.<br></br> <br></br> 
         In 2019 the opportunity presented itself to do something different from the Career I had been pursuing for 25 years. I decided that I wanted a complete change and soon became consumed by the thought of trying my hand at painting. I think the thought had always been there, just not in the front of my mind. Despite being an absolute beginner I did have a clear view of what I wanted to focus on; I wanted to see if I could capture the moments that I loved and experienced in nature and in particular I wanted to capture my encounters with Birds in their place. I find birds a continuous joy and inspiration. They are never mundane however common place they might be. I could try to capture the herring gull in all its forms, behaviours and contexts for the rest of my days. <br></br> <br></br> 
         So that is how the journey has started. I am self taught and will continue to develop my practice in this way - learning by doing and by trying to capture what is in my mind. I spend a lot of time out in the field watching, listening and learning - I capture it in sketches, photographs and in my mind. I use all of this to finish off drawings and paintings in the studio. <br></br> <br></br> 
         I have learned that the strive to be an artist can be a frustrating one as well as a uniquely enjoyable vocation. The drive comes from close encounters but a never quite fulfilled execution in the art that emerges. I am not trying to achieve perfection but the desire to capture and moment and share it with others. I think I will be on that journey for a long time.
         </p></div>
        <div className='artist-image'></div>
      </div>
      <div id='artist-container-ruth' className='artist-container'>
        <div>
        <h3>Ruth Allen Humphreys</h3>
        <p>Written with the necessary imposter syndrome that has always been my close companion, the reason my art features at all is because Raoul made it so. I work predominantly in black and white, whether that be with pen and ink or Lino cut printing. There is something very particular about working in black and white, shadow and light. I draw when my head is clouded, a reprieve to the writing that consumes most of the day, predominantly with my PhD research, interspersed with bursts of freedom in fiction. Place, in all its facets, is the junction where all three meet. I put pen to paper in attempts to capture something of the incredible place in which we live.
        </p>
        </div>
        <div className='artist-image'></div>
      </div>
    </div>
  )
}

export default Artists