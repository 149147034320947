import React, { useState } from 'react'
import './Works.scss'
import CatalogueItem from '../CatalogueItem/CatalogueItem.js'
import Contact from '../Contact/Contact.js'
import Martin1 from '../imgs/martin/martin-1.JPG'
import Martin2 from '../imgs/martin/martin-2.JPG'
import Martin3 from '../imgs/martin/martin-3.JPG'
import Martin4 from '../imgs/martin/martin-4.JPG' 
import Martin5 from '../imgs/martin/martin-5.JPG'
import Martin6 from '../imgs/martin/martin-6.JPG'
import Martin7 from '../imgs/martin/martin-feas-petrel.JPG'
import Martin8 from '../imgs/martin/martin-gull.JPG'
import Martin9 from '../imgs/martin/martin-kingfisher.JPG'
import Martin10 from '../imgs/martin/martin-peregrine.JPG'
import Martin11 from '../imgs/martin/martin-puffins.JPG'
import Martin12 from '../imgs/martin/martin-riverwarbler.JPG'
import Martin13 from '../imgs/martin/martin-rufousbushchat.JPG'
import Martin14 from '../imgs/martin/martin-snowyowl.JPG'
import Martin15 from '../imgs/martin/martin-trinidade-petrel.JPG'
import Martin16 from '../imgs/martin/martin-bee-eater-print.JPG'
import Raoul1 from '../imgs/raoul/raoul-bonxie.JPG'
import Raoul2 from '../imgs/raoul/raoul-buntings.JPG'
import Raoul3 from '../imgs/raoul/raoul-choughs.JPG'
import Raoul4 from '../imgs/raoul/raoul-choughs2.JPG'
import Raoul7 from '../imgs/raoul/raoul-ducks.JPG'
import Raoul8 from '../imgs/raoul/raoul-fulmar-shearwaters.JPG'
import Raoul9 from '../imgs/raoul/raoul-gannet.JPG'
import Raoul11 from '../imgs/raoul/raoul-godwits.JPG'
import Raoul12 from '../imgs/raoul/raoul-greenshanks.JPG'
import Raoul13 from '../imgs/raoul/raoul-gulls.JPG'
import Raoul15 from '../imgs/raoul/raoul-kestrels.JPG'
import Raoul16 from '../imgs/raoul/raoul-kittiwakes.JPG'
import Raoul18 from '../imgs/raoul/raoul-merlin.JPG'
import Raoul21 from '../imgs/raoul/raoul-pallidharrier.JPG'
import Raoul22 from '../imgs/raoul/raoul-peregrine.JPG'
import Raoul23 from '../imgs/raoul/raoul-purplesandpipers.JPG'
import Raoul24 from '../imgs/raoul/raoul-ruf.JPG'
import Raoul27 from '../imgs/raoul/raoul-shearwaters.JPG'
import Raoul28 from '../imgs/raoul/raoul-wagtail-bunting.JPG'
import Raoul29 from '../imgs/raoul/raoul-wheatear.JPG'
import Raoul30 from '../imgs/raoul/raoul-gannets-print.JPG'
import Raoul31 from '../imgs/raoul/raoul-ravens.JPG'
import Photo1 from '../imgs/photos/photo1.JPG'
import Photo2 from '../imgs/photos/photo2.JPG'
import Photo3 from '../imgs/photos/photo3.JPG'
import Photo4 from '../imgs/photos/photo4.JPG'
import Photo5 from '../imgs/photos/photo5.JPG'
import Photo6 from '../imgs/photos/photo6.JPG'
import Photo7 from '../imgs/photos/photo7.JPG'
import Photo8 from '../imgs/photos/photo8.JPG'
import Photo10 from '../imgs/photos/photo10.JPG'
import Photo11 from '../imgs/photos/photo11.JPG'
import Photo12 from '../imgs/photos/photo12.JPG'
import Photo14 from '../imgs/photos/photo14.JPG'
import Photo16 from '../imgs/photos/photo16.JPG'
import Photo17 from '../imgs/photos/photo17.JPG'
import Photo18 from '../imgs/photos/photo18.JPG'
import Photo20 from '../imgs/photos/photo20.JPG'
import Photo21 from '../imgs/photos/photo21.JPG'
import Photo22 from '../imgs/photos/photo22.JPG'
import Photo23 from '../imgs/photos/photo23.JPG'
import Photo24 from '../imgs/photos/photo24.JPG'
import Photo25 from '../imgs/photos/photo25.JPG'
import Photo26 from '../imgs/photos/photo26.JPG'
import Photo27 from '../imgs/photos/photo27.JPG'
import Photo28 from '../imgs/photos/photo28.JPG'
import Photo29 from '../imgs/photos/photo29.JPG'
import Photo31 from '../imgs/photos/photo31.JPG'
import Photo32 from '../imgs/photos/photo32.JPG'
import Photo33 from '../imgs/photos/photo33.JPG'
import Photo37 from '../imgs/photos/photo37.JPG'
import Photo38 from '../imgs/photos/photo38.JPG'
import Photo39 from '../imgs/photos/photo39.JPG'
import Photo40 from '../imgs/photos/photo40.JPG'
import Photo41 from '../imgs/photos/photo41.JPG'
import Photo42 from '../imgs/photos/photo42.JPG'
import Photo43 from '../imgs/photos/photo43.JPG'
import Photo44 from '../imgs/photos/photo44.JPG'
import Photo45 from '../imgs/photos/photo45.JPG'
import Photo46 from '../imgs/photos/photo46.JPG'
import Photo47 from '../imgs/photos/photo47.JPG'
import Photo48 from '../imgs/photos/photo48.JPG'
import Photo49 from '../imgs/photos/photo49.JPG'
import Photo50 from '../imgs/photos/photo50.JPG'
import Photo51 from '../imgs/photos/photo51.JPG'
import Photo52 from '../imgs/photos/photo52.JPG'
import Photo53 from '../imgs/photos/photo53.JPG'
import Photo54 from '../imgs/photos/photo54.JPG'
import Photo55 from '../imgs/photos/photo55.JPG'
import Photo56 from '../imgs/photos/photo56.JPG'
import Photo57 from '../imgs/photos/photo57.JPG'
import Photo58 from '../imgs/photos/photo58.JPG'
import Photo59 from '../imgs/photos/photo59.JPG'
import Photo60 from '../imgs/photos/photo60.JPG'
import Photo61 from '../imgs/photos/photo61.JPG'
import Photo62 from '../imgs/photos/photo62.JPG'
import Photo63 from '../imgs/photos/photo63.JPG'
import Photo64 from '../imgs/photos/photo64.JPG'
import Photo65 from '../imgs/photos/photo65.JPG'
import Photo66 from '../imgs/photos/photo66.JPG'
import Photo67 from '../imgs/photos/photo67.JPG'
import Photo68 from '../imgs/photos/photo68.JPG'
import Photo69 from '../imgs/photos/photo69.JPG'
import Photo70 from '../imgs/photos/photo70.JPG'
import Photo71 from '../imgs/photos/photo71.JPG'
import Ruth1 from '../imgs/ruth/ruth1.JPG'
import Ruth2 from '../imgs/ruth/ruth2.JPG'
import Ruth3 from '../imgs/ruth/ruth3.JPG'
import Ruth4 from '../imgs/ruth/ruth4.JPG'
import Ruth5 from '../imgs/ruth/ruth5.JPG'
import Ruth6 from '../imgs/ruth/ruth6.JPG'
import Ruth7 from '../imgs/ruth/ruth7.JPG'






function Works() {

  
  const [paintingsHover, setPaintingsHover] = useState(false)
  const [printsHover, setPrintsHover] = useState(false)
  const [photographyHover, setPhotographyHover] = useState(false)
  const [underlineAnim, setUnderlineAnim] = useState([
    {title: 'paintings', status: 'closed'},
    {title: 'prints', status: 'closed'},
    {title: 'photography', status: 'closed'},
  ])
  const [openType, setOpenType] = useState('none')
  


  const paintingsHoverTrigger = () => {
    setPaintingsHover(true)
    setUnderlineAnim([
      {title: 'paintings', status: 'open'},
      {title: 'prints', status: 'closed'},
      {title: 'photography', status: 'closed'},
    ])
  }

  const printsHoverTrigger = () => {
    setPrintsHover(true)
    setUnderlineAnim([
      {title: 'paintings', status: 'closed'},
      {title: 'prints', status: 'open'},
      {title: 'photography', status: 'closed'},
    ])
  }
  
  const photographyHoverTrigger = () => {
    setPhotographyHover(true)
    setUnderlineAnim([
      {title: 'paintings', status: 'closed'},
      {title: 'prints', status: 'closed'},
      {title: 'photography', status: 'open'},
    ])
  }



  const [items, setItems] = useState([
    
    {status: 'visible', artist: 'Martin Elliott', portrait: true,  price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Blackburnian Warbler, Bryher '22", link: Martin1},
    {status: 'visible', artist: 'Martin Elliott', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Long-tailed and Pomarine Skuas, Saltcoats, Firth of Clyde, '11", link: Martin2},
    {status: 'visible', artist: 'Martin Elliott', portrait: true,  price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Olive-backed Pipit, Setter, Shetland, '13", link: Martin3},
    {status: 'visible', artist: 'Martin Elliott', portrait: true,  price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Bonxie (Great Skua), Foula, Shetland, '11", link: Martin4},
    {status: 'visible', artist: 'Martin Elliott', portrait: true,  price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Great Shearwater and Storm Petrels, South West approaches, '09", link: Martin5},
    {status: 'visible', artist: 'Martin Elliott', portrait: false , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Lapwings and Greenland Wheatear, Sennen, '08", link: Martin6},
    {status: 'visible', artist: 'Martin Elliott', portrait: true , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "'The Dream-team' (Fea's Petrel, Wilson's Storm Petrel, Great Shearwater etc.), Scillonian III 'Pelagic', '01", link: Martin7},
    {status: 'visible', artist: 'Martin Elliott', portrait: false , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "'Smiffy', American Herring Gull, Newlyn Harbour, '21", link: Martin8},
    {status: 'visible', artist: 'Martin Elliott', portrait: true , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Kingfisher, Newlyn, '20", link: Martin9},
    {status: 'visible', artist: 'Martin Elliott', portrait: true , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Mobbed Goshawk, Nant Carfan, '10", link: Martin10},
    {status: 'visible', artist: 'Martin Elliott', portrait: false , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Fantasia on the Puffin, '19", link: Martin11},
    {status: 'visible', artist: 'Martin Elliott', portrait: true , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "River Warbler, Stoke Ferry, Norfolk, '89", link: Martin12},
    {status: 'visible', artist: 'Martin Elliott', portrait: false , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Rufous Bushchat/-tailed Robin whatever..., Lizard, '21", link: Martin13},
    {status: 'visible', artist: 'Martin Elliott', portrait: false , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Snowy Owl, Zennor(ish), '15", link: Martin14},
    {status: 'visible', artist: 'Martin Elliott', portrait: true , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Trinidad Petrel, Runnelstone, '19", link: Martin15},
    {status: 'visible', artist: 'Martin Elliott', portrait: true , price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Blue-Cheeked Bee-eater, Cowden, Yorkshire, '89", link: Martin16},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4', title: 'Bonxie on the Beach in Scuthie Bay, Orkney', link: Raoul1},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4', title: 'Little Bunting & Reed Bunting', link: Raoul2},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4', title: 'Chough family, recently fledged, near Boswens, st. Just', link: Raoul3},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4', title: 'Adult Chough with two fledglings, Nanquidno', link: Raoul4},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '£595.00', sold: false, type: 'painting', print: 'true', printSize: 'A4+A3', size: '69*49cm (not including frame)', print: 'true', title: 'Shelduck Scuffle, Hayle Estuary', link: Raoul7},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '£595.00', sold: false, type: 'painting', print: 'true', printSize: 'A4+A3', size: '69*49cm (not including frame)', title: 'A Fulmar, two Sooty Shearwaters & three Gannets in stormy seas', link: Raoul8},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', title: 'Gannet and Balearic Shearwater', link: Raoul9},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Male and female Bar-tailed Godwits, St Agnes, Isles of Scilly ', link: Raoul11},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: "Greenshanks, Porth Hellick, St Mary's, Isles of Scilly", link: Raoul12},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', title: 'Fulmar pair, Polpry Cove', link: Raoul13},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '£295.00', sold: false, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Kestrel with Fledglings, Nanquidno', link: Raoul15},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', title: 'Kittiwakes, Newlyn Harbour', link: Raoul16},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '£295.00', sold: false, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Young Merlin, Boswens Stone', link: Raoul18},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: false, price: '£295.00', sold: false, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Pallid Harrier, Trevilley near Lands End', link: Raoul21},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '£295.00', sold: false, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Newly Fledged Peregrine, Botallack', link: Raoul22},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Purple Sandpipers on barnacle covered rocks, Ayre Point near Gwynver', link: Raoul23},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Ruf, Porth Coose, St. Agnes, Isles of Scilly', link: Raoul24},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'A Pair of Corys Shearwaters, Isles of Scilly', link: Raoul27},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'St Agnes, Isles of Scilly, 18th April 2023', link: Raoul28},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '£195.00', sold: false, type: 'painting', print: 'true', printSize: 'A4', title: 'The Arrival of Spring, Northern Wheatear, Kenidjack', link: Raoul29},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: false, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Ravens, Iridescent in the Morning Sun, Kenidjack', link: Raoul31},
    {status: 'visible', artist: 'Raoul Humphreys', portrait: true, price: '', sold: true, type: 'painting', print: 'true', printSize: 'A4+A3', title: 'Gannets feeding off Cape Cornwall', link: Raoul30},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Slipway at Sennen Cove #1', link: Photo1},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Zennor Quoit #1', link: Photo2},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Zennor Quoit #2', link: Photo3},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Granite forms, Zennor Hill #1', link: Photo4},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true,  price: '', type: 'photograph', title: 'Granite forms, Zennor Hill #2', link: Photo5},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Wind worn Granite form, Zennor Hill', link: Photo6},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Longhorn cows, Brisons on the horizon', link: Photo7},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Levant from Carn Du', link: Photo8},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Chough family, Nanquidno', link: Photo10},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Kestrel family, Nanquidno', link: Photo11},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Carn Les Boel towards the Longships', link: Photo12},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Wave breaking on Longships', link: Photo14},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Chough in the thrift, Nanquidno', link: Photo16},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Mine workings, Kenidjack #1', link: Photo17},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Granite forms, Porthledden', link: Photo18},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Gwynver Beach towards Aire Point', link: Photo20},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Sennen Cove and Roundhouse', link: Photo21},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Lanyon Quoit', link: Photo22},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Holly tree, St. Loy', link: Photo23},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Nanjizel Bay', link: Photo24},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Engine house, Botallack', link: Photo25},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Mine workings, kenidjack #2', link: Photo26},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Boswens Stone', link: Photo27},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Chun Quoit', link: Photo28},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Bollowal Barrow', link: Photo29},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Going out on the tide', link: Photo31},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Longships Lighthouse, storm sky', link: Photo32},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Grey Seal bull, River Cove', link: Photo33},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Greater Black Back Gull. Nanquidno', link: Photo37},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Gwynver sunset #1', link: Photo38},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Gwynver sunset #2', link: Photo39},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Gurnards Head', link: Photo40},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Gig team, Newlyn Harbour', link: Photo41},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true,  price: '', type: 'photograph', title: 'Boscregan', link: Photo42},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'The Brisons from Boscregan', link: Photo43},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Men Scryfa towards Ding Dong', link: Photo44},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Wave breaking on Aire Point', link: Photo45},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Grey Seal pup, River Cove', link: Photo46},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Longships Lighthouse, close to sunset', link: Photo47},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Boskednan Circle (Nine Maidens)', link: Photo48},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Cape Cornwall from Kenidjack #1', link: Photo49},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Cape Cornwall from kenidjack #2', link: Photo50},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Men an Tol', link: Photo51},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'The old man of Gurland', link: Photo52},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Mulfra Quiot', link: Photo53},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Carn Boel from Nanjizal', link: Photo54},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Cape Cornwall from Kenidjack #3', link: Photo55},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false,  price: '', type: 'photograph', title: 'Kenidjack, sunny day', link: Photo56},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Harbour view, Newlyn', link: Photo57},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Kidney Vetch, Nanjizal', link: Photo58},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Wave breaking, Porthledden, Cape Cornwall', link: Photo59},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Porthledden Cove towards Cape Cornwall', link: Photo60},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Mine workings, Kenidjack #3', link: Photo61},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '', type: 'photograph', title: 'Raven calling', link: Photo62},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Shag, Carn Les Boel', link: Photo63},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Cape Cornwall, breaking wave', link: Photo64},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Snow at Chun Quoit', link: Photo65},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Oyster Catchers, Newlyn', link: Photo66},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Penberth Cove', link: Photo67},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Entrance way, Chun Castle', link: Photo68},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Slipway at Sennen Cove #2', link: Photo69},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: false, price: '', type: 'photograph', title: 'Brisons from Porth Nanven', link: Photo70},
    {status: 'hidden', artist: 'Raoul Humphreys', portrait: true, price: '',  type: 'photograph', title: 'Boats are in, Newlyn', link: Photo71},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: false, price: '', type: 'painting', print: 'true', title: 'From Carn Galver', link: Ruth1},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: false, price: '', type: 'painting', print: 'true', title: 'Carn Kenidjack', link: Ruth2},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: false, price: '', type: 'painting', print: 'true', title: 'Granite form, Zennor hill', link: Ruth3},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: false, price: '', type: 'painting', print: 'true', title: 'Boskednan stone circle', link: Ruth4},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: false, price: '', type: 'painting', print: 'true', title: 'Men-an-tol', link: Ruth5},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: true, price: '', type: 'painting', print: 'true', title: 'Down deep', link: Ruth6},
    {status: 'visible', artist: 'Ruth Humphreys', portrait: false, price: '', type: 'painting', print: 'true', title: 'Chun night', link: Ruth7}, 
  ])


  // set paintings

  const paintingsClick = () => {
    setOpenType('paintings')
    setItems([...items].map((item) =>(item.type === 'painting' 
     ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link}
     : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title,  link: item.link})))

    }

  const martinPaintingsClick = () => {
    setOpenType('paintings')
    setItems([...items].map((item) =>(item.type === 'painting' && item.artist === 'Martin Elliott' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

  const raoulPaintingsClick = () => {
    setOpenType('paintings')
    setItems([...items].map((item) =>(item.type === 'painting' && item.artist === 'Raoul Humphreys' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

  const ruthPaintingsClick = () => {
    setOpenType('paintings')
    setItems([...items].map((item) =>(item.type === 'painting' && item.artist === 'Ruth Humphreys' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

  // set photographs

  const photosClick = () => {
    setOpenType('photos')
    setItems([...items].map((item) =>(item.type === 'photograph' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  
  }

  const raoulPhotosClick = () => {
    setOpenType('photos')
    setItems([...items].map((item) =>(item.type === 'photograph' && item.artist === 'Raoul Humphreys' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

// set prints

  const printsClick = () => {
    setOpenType('prints')
    setItems([...items].map((item) =>(item.print === 'true'
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  
  }

  const raoulPrintsClick = () => {
    setOpenType('prints')
    setItems([...items].map((item) =>(item.print === 'true' && item.artist === 'Raoul Humphreys' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

  const ruthPrintsClick = () => {
    setOpenType('prints')
    setItems([...items].map((item) =>(item.print === 'true' && item.artist === 'Ruth Humphreys' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

  const martinPrintsClick = () => {
    setOpenType('prints')
    setItems([...items].map((item) =>(item.print === 'true' && item.artist === 'Martin Elliott' 
    ? {status: 'visible', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link} 
    : {status: 'hidden', artist: item.artist, portrait: item.portrait, price: item.price, sold: item.sold, print: item.print, printSize: item.printSize, type: item.type, title: item.title, link: item.link})))
  }

 


  return (
   <div className='works'>
     <div className='artType-container'>
      <div className='artType'>
      <h3 className='artType-link' id='paintings-artType' onClick={paintingsClick} onMouseEnter={() => paintingsHoverTrigger()} onMouseLeave={() => setPaintingsHover(false)}>Artworks</h3>
      {paintingsHover ? 
        <div className='hover-list' id='paintings-hover-list' onMouseEnter={() => paintingsHoverTrigger()} onMouseLeave={() => setPaintingsHover(false)}>
           <div className='underline'><hr className={underlineAnim[0].title === 'paintings' && underlineAnim[0].status === 'open' ? 'hr-open' : ''}></hr></div>
          <h4 onClick={raoulPaintingsClick}>Raoul Humphreys</h4>
          <h4 onClick={ruthPaintingsClick}>Ruth Humphreys</h4>
          <h4 onClick={martinPaintingsClick}>Martin Elliott</h4>
        </div> : ''}
      </div>


      <div className='artType'>
      <h3 id='photography-artType' className='artType-link' onClick={photosClick} onMouseEnter={() => photographyHoverTrigger()} onMouseLeave={() => setPhotographyHover(false)}>Photography</h3>
     
      {photographyHover ?
        <div className='hover-list' id='photography-hover-list' onMouseEnter={() => photographyHoverTrigger()} onMouseLeave={() => setPhotographyHover(false)}>
          <div className='underline'><hr className={underlineAnim[2].title === 'photography' && underlineAnim[2].status === 'open' ? 'hr-open' : ''}></hr></div>
          <h4 onClick={raoulPhotosClick}>Raoul Humphreys</h4>
        </div> : ''}
      </div>
    
      
      <div className='artType'>
      <h3 id='prints-artType' className='artType-link' onClick={printsClick} onMouseEnter={() => printsHoverTrigger()} onMouseLeave={() => setPrintsHover(false)}>Prints</h3>
      {printsHover ?
        <div id='prints-hover-list' className='hover-list' onMouseEnter={() => printsHoverTrigger()} onMouseLeave={() => setPrintsHover(false)}>
          <div className='underline'><hr className={underlineAnim[1].title === 'prints' && underlineAnim[1].status === 'open' ? 'hr-open' : ''}></hr></div>
          <h4 onClick={raoulPrintsClick}>Raoul Humphreys</h4>
          <h4 onClick={ruthPrintsClick}>Ruth Humphreys</h4>
          <h4 onClick={martinPrintsClick}>Martin Elliott</h4>
        </div> : ''}
      </div>
      
    </div>

    <div className='catalogue-items-container'>
    {items.map((item) => (item.status === 'visible' 
    ? <CatalogueItem openType={openType} link={item.link} artist={item.artist} portrait={item.portrait} type={item.type} title={item.title} size={item.size} sold={item.sold} print={item.print} printSize={item.printSize} price={item.price} /> 
    : ''))}
    
    </div>
   </div> 
    
  )
}

export default Works