import React, { useState } from 'react'
import './Landing.scss'
import OwlImg from '../imgs/martin/martin-snowyowl.JPG'
import Rufous from '../imgs/martin/martin-rufousbushchat.JPG'
import Bonxie from '../imgs/raoul/raoul-bonxie.JPG'
import Shellduck from '../imgs/raoul/raoul-ducks.JPG'
import Sandpiper from '../imgs/raoul/raoul-sandpipers.JPG'
import MenAnTol from '../imgs/ruth/ruth-menantol.JPG'
import Fox from '../imgs/ruth/ruth4.JPG'
import AboutImg from '../imgs/extras/shop1.JPG'
import AboutImg2 from '../imgs/extras/shop2.JPG'
import AboutImg3 from '../imgs/extras/shop3.JPG'

function Landing() {

  const slides = [OwlImg, Rufous, Bonxie, Sandpiper, MenAnTol, Fox]
  const [currentIndex, setCurrentIndex] = useState(0)
  const [animation, setAnimation] = useState('landing-img landing-img-anim1')

  const resetAnim = () => {
    switch(currentIndex) {
      case 0:
         setAnimation('landing-img landing-img-anim1')
        break;
      case 2:
         setAnimation('landing-img landing-img-anim2')
        break;
      case 4: 
       setAnimation('landing-img landing-img-anim3')
      break;
    }
  }

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slides.length - 2 : currentIndex - 2
    setCurrentIndex(newIndex)
    resetAnim()
  }
  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 2
    const newIndex = isLastSlide ? 0 : currentIndex + 2
    setCurrentIndex(newIndex)
    resetAnim()
  }


  return (
    <div className='landing-container'>
        
          <div className='landing-grid'>
              <div className={animation} id="landing-img1" style={{backgroundImage: `url(${slides[currentIndex]})`}}></div>
              <div className={animation} id="landing-img2" style={{backgroundImage: `url(${slides[currentIndex + 1]})`}}></div>
          </div>

          <div className='landing-grid-mobile'>
              <div id='owlImg' className='landing-img-mobile' style={{backgroundImage: `url(${OwlImg})`}}></div>
              <div id='bonxie' className='landing-img-mobile' style={{backgroundImage: `url(${Shellduck})`}}></div>
              {/*<div id='menAnTol' className='landing-img-mobile' style={{backgroundImage: `url(${MenAnTol})`}}></div>*/}

          </div>
          <div className='buttons'>
            <button id="landing-btn1" className='landing-btn' onClick={goToPrevious} >&#8678;</button>
            <button id="landing-btn2" className='landing-btn' onClick={goToNext}>&#8680;</button>
          </div>
          
       <div id='about' className='about-container'>
        
          <div id="about-pic" style={{backgroundImage: `url(${AboutImg})`}}></div>
          <div id="about-pic2" style={{backgroundImage: `url(${AboutImg2})`}}></div>
          <div id="about-pic3" style={{backgroundImage: `url(${AboutImg3})`}}></div>
    
          <p id="about-text"> 
          <br></br>     
          <h3 className='about-title'>~ About Us ~</h3>
            London House Studio is a family run Gallery and Bookshop located on Fore 
            Street, St Just. London House has been the name of the shop since the 1860s.
             For 100 years it was a family run Drapery. Their trips to London to procure
              the latest in fabrics gave the shop its name. Now we are home to a 
              selection of original artwork, photography, prints, cards and books. We 
              are home to the studio of Raoul Humphreys, an artist specialising in the 
              local wildlife, with a passion for Birds. We have a selection of both new
               and preloved books, reflecting our bookish nature. When not in the shop 
               or studio we are most likely found out walking in West Penwith. If you 
               are interested in original artworks or prints please get in touch using 
               the details on the contact page. We hope you enjoy browsing.  
          </p>
       </div>
    </div>
  )
}

export default Landing