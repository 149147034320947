import React, { useState } from 'react'
import './Nav.scss'

function Nav({ clickHome, clickWorks, clickBlog, clickContact, clickArtists, hamburgerOpen, clickHamburger }) {


  
  

  return (
    <div className='nav-container'>
        <nav className='navbar'>
            <div id='navigation' className='nav-links-container'>
                <a className="nav-link" href="#" onClick={() => clickHome()}>Home</a>
                <a className="nav-link" href="#" onClick={() => clickWorks()}>Works</a>
                <a className="nav-link" href="#" onClick={() => clickArtists()}>Artists</a>
                {/*<a className="nav-link" href="#blog" onClick={() => clickBlog()}> News</a>*/}
                <a className="nav-link" href="#contact" onClick={() => clickContact()}>Contact</a>
            </div>

            <div className={hamburgerOpen ? 'hamburger-container open' : 'hamburger-container'} onClick={() => clickHamburger()}>
              <span className='line'></span>
              <span className='line'></span>
              <span className='line'></span>
            </div>
           
        </nav>
        <img id="logo" src='/logo.jpeg' alt="logo"></img>
        {hamburgerOpen ? 
        <div className='mobile-nav-overlay'>
          <div className='mobile-nav-menu'>
                <a className="mobile-nav-link" href="#" onClick={() => clickHome()}>Home</a>
                <a className="mobile-nav-link" href="#" onClick={() => clickWorks()}>Works</a>
                <a className="mobile-nav-link" href="#" onClick={() => clickArtists()}>Artists</a>
                {/*<a className="mobile-nav-link" href="#blog" onClick={() => clickBlog()}> News</a>*/}
                <a className="mobile-nav-link" href="#contact" onClick={() => clickContact()}>Contact</a>
          </div>
        </div> 
        : ''}
    </div>
  )
}

export default Nav