
import { useState } from 'react'
import './App.scss';
import Nav from './components/Nav/Nav.js'
import Landing from './components/Landing/Landing.js'
import Works from './components/Works/Works.js'
import Artists from './components/Artists/Artists.js'
import Blog from './components/Blog/Blog.js'
import Contact from './components/Contact/Contact.js'


function App() {

  const [hamburgerOpen, setHamburgerOpen] = useState(false)
  const clickHamburger = () => {
    setHamburgerOpen(!hamburgerOpen)
  }

  const [section, setSection] = useState([
    {name: 'home', status: 'open'},
    {name: 'works', status: 'closed'},
    {name: 'artists', status: 'closed'},
    {name: 'blog', status: 'closed'},
  ])

  const clickHome = () => {
    setSection([
      {name: 'home', status: 'open'},
      {name: 'works', status: 'closed'},
      {name: 'artists', status: 'closed'},
      {name: 'blog', status: 'closed'},
    ])
    setHamburgerOpen(false)
  }


  const clickWorks = () => {
    setSection([
      {name: 'home', status: 'closed'},
      {name: 'works', status: 'open'},
      {name: 'artists', status: 'closed'},
      {name: 'blog', status: 'closed'},
    ])
    setHamburgerOpen(false)
  }

  const clickArtists = () => {
    setSection([
      {name: 'home', status: 'closed'},
      {name: 'works', status: 'closed'},
      {name: 'artists', status: 'open'},
      {name: 'blog', status: 'closed'},
    ])
    setHamburgerOpen(false)
  }

  const clickBlog = () => {
    setSection([
      {name: 'home', status: 'closed'},
      {name: 'works', status: 'closed'},
      {name: 'artists', status: 'closed'},
      {name: 'blog', status: 'open'},
    ])
    setHamburgerOpen(false)
  }

  const clickContact = () => {
    setSection([
      {name: 'home', status: 'open'},
      {name: 'works', status: 'closed'},
      {name: 'artists', status: 'closed'},
      {name: 'blog', status: 'closed'},
    ])
    setHamburgerOpen(false)
  }


  console.log(section);

  return (
    <div className="App">
      <Nav clickHome={clickHome} clickWorks={clickWorks} clickBlog={clickBlog} clickContact={clickContact} clickArtists={clickArtists} clickHamburger={clickHamburger} hamburgerOpen={hamburgerOpen} />
      {section[0].status === 'open' ? <Landing /> : ''}
      {section[1].status === 'open' ? <Works /> : ''}
      {section[2].status === 'open' ? <Artists /> : ''}
      {section[3].status === 'open' ? <Blog /> : ''}
      {section[0].status === 'open' ? <Contact /> : ''}
      
      
    </div>
  );
}

export default App;
